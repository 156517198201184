/**
 * This must be imported standalone for downstream treeshaking to work.
 */
import '../../utils/languages';

import {
  Box,
  BoxOwnProps,
  BoxProps,
  CopyButton,
  CopyButtonProps,
  Icon,
  IIconProps,
  NoSsr,
  PolymorphicComponentProps,
} from '@stoplight/mosaic';
import cn from 'clsx';
import PrismHighlight, { Language } from 'prism-react-renderer';
import * as React from 'react';
import { memo } from 'react';

import { useCodeTheme } from '../../themes';
import { CodeViewerLanguage, Prism } from '../../utils';

type CodeViewerBaseProps = {
  value: string;
  showLineNumbers?: boolean;
  showMaxLines?: number;
  title?: string;
  noCopyButton?: boolean;

  /**
   * Props to pass to the inner code container
   */
  innerProps?: BoxOwnProps;

  /**
   * Defaults to 500
   */
  maxHeight?: string;

  /**
   * Applied to the innermost highlight container
   */
  highlightPadding?: { x: number; y: number };
};

type CodeViewerWithLanguage = CodeViewerBaseProps & {
  language: CodeViewerLanguage;
  customLanguage?: never;
};

type CodeViewerWithCustomLanguage = CodeViewerBaseProps & {
  customLanguage: string;
  language?: never;
};

export type CodeViewerOwnProps = CodeViewerWithLanguage | CodeViewerWithCustomLanguage;

export type CodeViewerProps<E extends React.ElementType = 'pre'> = PolymorphicComponentProps<E, CodeViewerOwnProps>;

export const DEFAULT_HIGHLIGHT_PADDING = { x: 15, y: 12 };
export const CODE_LINE_HEIGHT = 21;

export const CodeViewer: <E extends React.ElementType = 'pre'>(props: CodeViewerProps<E>) => React.ReactElement | null =
  memo(
    ({
      value,
      className,
      language,
      customLanguage,
      showLineNumbers,
      showMaxLines,
      title,
      noCopyButton,
      highlightPadding = DEFAULT_HIGHLIGHT_PADDING,
      ...props
    }) => {
      const code = (value || '').trim();

      const { renderHighlight, lines } = useHighlight({
        value: code,
        language: language || customLanguage,
        showLineNumbers,
        showMaxLines,
        style: {
          padding:
            highlightPadding.y === highlightPadding.x
              ? `${highlightPadding.y}px`
              : `${highlightPadding.y}px ${highlightPadding.x}px`,
          fontFamily: 'var(--font-code)',
          fontSize: 'var(--fs-code)',
          lineHeight: 'var(--lh-code)',
        },
      });

      const _className = cn('sl-code-viewer', className);

      return (
        <CodeContainer
          pos="relative"
          role="group"
          title={title}
          className={_className}
          tabIndex={0}
          outline="none"
          renderHighlight={renderHighlight}
          lines={lines}
          copyValue={noCopyButton ? undefined : code}
          highlightPadding={highlightPadding}
          language={language}
          {...props}
        />
      );
    },
  );

const lineNoWidths: Record<number, number> = {
  1: 28, // treat 1 and 2 digit widths the same to reduce mis-alignment in common use case of less < 100 lines
  2: 28,
  3: 36,
  4: 42,
  5: 50,
  6: 58,
};

const useCode = (value: string, maxLines: number): { code: string; loc: number; trimmed: boolean } => {
  return React.useMemo(() => {
    const lines = /\r?\n/g;

    if (maxLines < 1) {
      return {
        code: value,
        // Count newlines and pad to match actual line numbers
        loc: (value.match(lines) || []).length + 1,
        trimmed: false,
      };
    }

    let code = '';
    let loc = 1;
    for (; loc <= maxLines; loc++) {
      const lastIndex = lines.lastIndex;
      const fragment = lines.exec(value);
      if (fragment) {
        code += `${value.slice(lastIndex, fragment.index)}${loc === maxLines ? '' : '\n'}`;
      } else {
        code += value.slice(lastIndex);
        break;
      }
    }

    return {
      code,
      loc,
      trimmed: loc > maxLines,
    };
  }, [value, maxLines]);
};

export const useHighlight = ({
  value,
  language,
  showLineNumbers,
  showMaxLines = -1,
  style: propStyle = {},
}: {
  value: string;
  language?: CodeViewerLanguage | string;
  showLineNumbers?: boolean;
  showMaxLines?: number;
  style?: React.CSSProperties;
}): { pad: number; lines: number; gutterWidth: number; renderHighlight: () => JSX.Element } => {
  const theme = useCodeTheme();
  const [actualMaxLines, setActualMaxLines] = React.useState<number>(showMaxLines);
  const { code, loc: lines, trimmed } = useCode(value, actualMaxLines);

  React.useEffect(() => {
    setActualMaxLines(showMaxLines);
  }, [showMaxLines]);

  // Determine padding needed (length of line number length)
  const pad = String(lines).length;

  const gutterWidth = lineNoWidths[pad];

  return {
    pad,
    lines,
    gutterWidth: showLineNumbers ? gutterWidth : 0,
    renderHighlight: () => (
      <PrismHighlight code={code} language={language?.toLowerCase() as Language} theme={theme} Prism={Prism}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <>
            <Box className={cn('sl-code-highlight', className)} style={{ ...style, ...propStyle }}>
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })} className="sl-flex">
                  {showLineNumbers ? (
                    <Box
                      className="sl-code-highlight__ln"
                      userSelect="none"
                      flexShrink={0}
                      opacity={50}
                      style={{
                        width: gutterWidth,
                        fontSize: '0.9em',
                        paddingTop: '0.1em',
                        lineHeight: propStyle.lineHeight ?? 'var(--lh-code)',
                      }}
                    >
                      {i + 1}
                    </Box>
                  ) : null}

                  <div className="sl-flex-1 sl-break-all">
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                </div>
              ))}
            </Box>
            {trimmed ? (
              <ShowMoreLessButton icon="arrow-down" onClick={() => setActualMaxLines(-1)}>
                show more
              </ShowMoreLessButton>
            ) : showMaxLines !== actualMaxLines ? (
              <ShowMoreLessButton icon="arrow-up" onClick={() => setActualMaxLines(showMaxLines)}>
                show less
              </ShowMoreLessButton>
            ) : null}
          </>
        )}
      </PrismHighlight>
    ),
  };
};

export const HighlightCodeFallback = ({
  lines,
  highlightPadding = DEFAULT_HIGHLIGHT_PADDING,
}: {
  lines?: number;
  highlightPadding?: CodeViewerOwnProps['highlightPadding'];
}) => {
  return (
    <Box
      className="sl-highlight-code__fallback"
      fontSize="sm"
      color="muted"
      style={{
        /**
         * These values are important! They must result in fallback being the same height as rendered code viewer
         * so that there is not jumpyness in article on initial load
         */

        padding:
          highlightPadding.y === highlightPadding.x
            ? `${highlightPadding.y}px`
            : `${highlightPadding.y}px ${highlightPadding.x}px`,
        // could pull this from theme in future if we get --fs-code and --ln-code accessible via js in ssr
        minHeight: lines ? `${lines * CODE_LINE_HEIGHT + DEFAULT_HIGHLIGHT_PADDING.y * 2}px` : undefined,
      }}
    >
      preparing...
    </Box>
  );
};

export const CodeContainer = memo(function CodeContainer({
  title,
  children,
  maxHeight = 500,
  innerProps = {},
  renderHighlight,
  lines,
  copyValue,
  highlightPadding = DEFAULT_HIGHLIGHT_PADDING,
  language,
  ...props
}: BoxProps<React.ElementType> & {
  // passed in this way rather than along w children so that we can NoSsr CodeContainer and have renderHighlight not run on server at all
  renderHighlight: () => JSX.Element;

  maxHeight?: number;
  innerProps?: CodeViewerOwnProps['innerProps'];
  language?: CodeViewerLanguage | string;

  // optional, aids with fallback height calculation during ssr
  lines?: number;
  copyValue?: string;
  highlightPadding?: CodeViewerOwnProps['highlightPadding'];
}) {
  const defaultElement = language === 'undefined' || !language ? 'div' : 'pre';
  return (
    <Box as={defaultElement} overflowY="hidden" overflowX="hidden" {...props}>
      {title && (
        <Box
          className="sl-code-viewer__title"
          py={2.5}
          px={4}
          fontFamily="ui"
          pointerEvents="none"
          bg="canvas-tint"
          fontWeight="medium"
          fontSize="lg"
          borderB
        >
          {title.replace(/__/g, ' ')}
        </Box>
      )}

      <Box
        className="sl-code-viewer__scroller"
        overflowY="auto"
        overflowX="auto"
        style={{ maxHeight: maxHeight }}
        {...innerProps}
      >
        <NoSsr fallback={<HighlightCodeFallback lines={lines} highlightPadding={highlightPadding} />}>
          <CodeContainerRenderHighlight renderHighlight={renderHighlight} />
        </NoSsr>
      </Box>

      {copyValue ? (
        <NoSsr>
          <CornerCopyButton copyValue={copyValue} />
        </NoSsr>
      ) : null}
    </Box>
  );
});

export const CornerCopyButton = (props: CopyButtonProps) => {
  return (
    <Box
      pos="absolute"
      right={0}
      pr={2}
      zIndex={20}
      style={{ top: 9 }}
      visibility={{ default: 'invisible', groupHover: 'visible' }}
      data-testid="copy-button"
    >
      <CopyButton {...props} />
    </Box>
  );
};

const ShowMoreLessButton: React.FC<BoxProps<'button'> & Pick<IIconProps, 'icon'>> = ({ children, icon, ...props }) => {
  return (
    <Box
      as="button"
      bg={{ default: 'canvas-200', hover: 'canvas-300' }}
      py={3}
      borderT
      borderColor="light"
      my="auto"
      w="full"
      textAlign="center"
      display="block"
      pos="relative"
      {...props}
    >
      <Box
        as="span"
        display="block"
        pos="absolute"
        w="full"
        h={14}
        top={-14}
        style={{
          pointerEvents: 'none',
          background: 'linear-gradient(0deg, var(--color-canvas-200) 25%, transparent 100%)',
        }}
      />
      <Icon icon={icon} />
      <Box as="span" px={2} fontWeight="medium">
        {children}
      </Box>
      <Icon icon={icon} />
    </Box>
  );
};

function CodeContainerRenderHighlight({ renderHighlight }: { renderHighlight: () => JSX.Element }) {
  return renderHighlight ? renderHighlight() : null;
}
